<!--@Time : 2021/12/28 10:52-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <v-row>
    <v-col>
      <v-alert
          text
          dense
          color="blue-grey"
          icon="mdi-information"
          border="left"
      >
        The following information shall be strictly based on the
        completed competition entries. All information collected will be
        shared only by the participating schools who are willing to
        participate in the subsequent applied research and teaching
        practice. Any commercial use without authorization of the
        competing teams and the organizing committee is prohibited.
      </v-alert>
      <v-alert
          text
          dense
          color="blue-grey"
          icon="mdi-translate"
          border="left"
      >
        Both English and Chinese are acceptable.
      </v-alert>
      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-1-box</v-icon>
          Summary
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-subheader> Overview Description </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-textarea
                  v-model="features.summary.overview_description"
                  :rules="wordsRules"
                  counter="100"
                  :counter-value="(v) => v.trim().length"
                  hint="项目简介"
                  label="Describe your house prototype with no more than 100 words"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-subheader>Innovations &amp; Highlights</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-textarea
                  v-model="features.summary.innovations_highlights"
                  :rules="wordsRules"
                  counter="100"
                  hint="创新点及亮点"
                  label="List the selling points of your house prototype with no more than 100 words"
                  :counter-value="(v) => v.trim().length"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-2-box</v-icon>
          Design Parameters
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-subheader> General </v-subheader>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Height"
                  suffix="m"
                  hint="建筑高度"
                  dense
                  v-model="features.design_parameters.general.height"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Floor Number"
                  suffix=""
                  hint="建筑层数"
                  dense
                  v-model="features.design_parameters.general.floor_number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Floor Area"
                  suffix="m2"
                  hint="建筑面积"
                  dense
                  v-model="features.design_parameters.general.floor_area"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Window-to-wall Ratio"
                  suffix=""
                  hint="窗墙比"
                  dense
                  v-model="features.design_parameters.general.window_to_wall_ratio"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="Air Change Rate"
                  suffix=""
                  hint="换气次数（N50）"
                  dense
                  v-model="features.design_parameters.general.air_change_rate"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Loads </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Energy Consumption Density"
                  suffix="kWh/(m2-a)"
                  hint="单位建筑面积能耗（设计值或模拟值）"
                  dense
                  v-model="features.design_parameters.loads.energy_consumption_density"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Space Heating Load"
                  suffix="kWh/(m2-a)"
                  hint="供暖年耗热量（设计值或模拟值）"
                  dense
                  v-model="features.design_parameters.loads.space_heating_load"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Space Cooling Load"
                  suffix="kWh/(m2-a)"
                  hint="供冷年耗冷量（设计值或模拟值）"
                  dense
                  v-model="features.design_parameters.loads.space_cooling_load"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-3-box</v-icon>
          Building Envelope
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-subheader> Foundation &amp; Structure</v-subheader>
            </v-col>

            <v-col cols="5">
              <v-text-field
                  label="Foundation"
                  suffix=""
                  hint="独立基础、条形基础、筏板基础、箱型基础等"
                  dense
                  v-model="features.building_envelope.foundation_structure.foundation"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Structure"
                  suffix=""
                  hint="轻型/重型钢结构、木结构、钢筋混凝土结构等"
                  dense
                  v-model="features.building_envelope.foundation_structure.structure"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.exterior_walls" :key="'exterior_walls'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0" > Exterior Walls </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="稻草板、竹木纤维集成板、发泡混凝土板等"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="外墙传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="外墙供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.exterior_walls, 'exterior_walls')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.exterior_walls, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.interior_walls" :key="'interior_walls'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Interior Walls </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="内墙材料"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="内墙传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="内墙供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.interior_walls, 'exterior_walls')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.interior_walls, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.floors" :key="'floors'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Floors</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="地板材料"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="地板传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="地板供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.floors, 'exterior_walls')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.floors, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.roofs" :key="'roofs'+index">
            <v-col cols="2">
               <v-subheader v-if="index===0">Roofs</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="屋面材料"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="屋面传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="屋面供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.roofs, 'exterior_walls')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.roofs, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.exterior_doors" :key="'exterior_doors'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Exterior Doors </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="入户门材料"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="入户门传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="入户门供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.exterior_doors, 'exterior_walls')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.exterior_doors, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.exterior_windows" :key="'exterior_windows'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Exterior Windows</v-subheader>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Components"
                  suffix=""
                  hint="外窗（含透光幕墙）材料"
                  dense
                  v-model="item.components"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="外窗（含透光幕墙）传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  label="SHGC"
                  suffix=""
                  hint="外窗（含透光幕墙）太阳得热系数"
                  dense
                  v-model="item.shgc"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="外窗（含透光幕墙）供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.exterior_windows, 'exterior_windows')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.exterior_windows, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.building_envelope.new_materials" :key="'new_materials'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">New Materials</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Description"
                  suffix=""
                  hint="新材料描述"
                  dense
                  v-model="item.description"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Transfer Coefficient"
                  suffix="W/(m2-K)"
                  hint="传热系数"
                  dense
                  v-model="item.heat_transfer_coefficient"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.building_envelope.new_materials, 'new_materials')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.building_envelope.new_materials, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-subheader>Additional </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="features.building_envelope.additional.supplementary_information"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-4-box</v-icon>
          Energy System
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row v-for="(item, index) in features.energy_system.photovoltaics" :key="'photovoltaics'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Photovoltaics </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="PV Panels"
                  suffix=""
                  hint="单晶硅、多晶硅、非晶硅等"
                  dense
                  v-model="item.pv_panels"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Efficiency"
                  suffix="%"
                  hint="光电转换效率"
                  dense
                  v-model="item.efficiency"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="光伏供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.energy_system.photovoltaics, 'photovoltaics')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.energy_system.photovoltaics, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.energy_system.wind" :key="'wind'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Wind</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Wind Turbines"
                  suffix=""
                  hint="风轮类型"
                  dense
                  v-model="item.wind_turbines"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Efficiency"
                  suffix="%"
                  hint="风电转换效率"
                  dense
                  v-model="item.efficiency"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="风轮供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.energy_system.wind, 'wind')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.energy_system.wind, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.energy_system.other_renewable_energy" :key="'other_renewable_energy'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Other Renewable Energy</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Description"
                  suffix=""
                  hint="其它可再生能源"
                  dense
                  v-model="item.description"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Efficiency"
                  suffix="%"
                  hint="转换效率"
                  dense
                  v-model="item.efficiency"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.energy_system.other_renewable_energy, 'other_renewable_energy')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.energy_system.other_renewable_energy, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.energy_system.battery" :key="'battery'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Battery </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Battery Type"
                  suffix=""
                  hint="锂离子、铅酸、铅碳、锌溴、钠硫等"
                  dense
                  v-model="item.battery_type"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Capacity"
                  suffix="kWh"
                  hint="电池容量"
                  dense
                  v-model="item.capacity"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="电池供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.energy_system.battery, 'battery')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.energy_system.battery, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-subheader>Additional </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="features.energy_system.additional.supplementary_information"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-5-box</v-icon>
          HVAC &amp; Plumbing
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row v-for="(item, index) in features.hvac_plumbing.space_heating" :key="'space_heating'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Space Heating</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heating Source"
                  suffix=""
                  hint="供暖形式"
                  dense
                  v-model="item.heating_source"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="COP"
                  suffix=""
                  hint="热源机组性能系数"
                  dense
                  v-model="item.cop"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="热源机组供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.space_heating, 'space_heating')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.space_heating, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.hvac_plumbing.space_cooling" :key="'space_cooling'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Space Cooling</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Cooling Source"
                  suffix=""
                  hint="供冷形式"
                  dense
                  v-model="item.cooling_source"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="EER"
                  suffix=""
                  hint="冷源机组能效指标"
                  dense
                  v-model="item.eer"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="热源机组供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.space_cooling, 'space_cooling')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.space_cooling, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row  v-for="(item, index) in features.hvac_plumbing.domestic_hot_water" :key="'domestic_hot_water'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Domestic Hot Water</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Domestic Hot Water Source"
                  suffix=""
                  hint="生活热水形式"
                  dense
                  v-model="item.domestic_hot_water_source"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Efficiency"
                  suffix=""
                  hint="生活热水制热效率"
                  dense
                  v-model="item.efficiency"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="生活热水机组供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.domestic_hot_water, 'domestic_hot_water')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.domestic_hot_water, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row  v-for="(item, index) in features.hvac_plumbing.heat_recovery" :key="'heat_recovery'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Heat Recovery</v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Heat Recovery Type"
                  suffix=""
                  hint="显热回收、全热回收等"
                  dense
                  v-model="item.heat_recovery_type"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Efficiency"
                  suffix=""
                  hint="热回收效率"
                  dense
                  v-model="item.efficiency"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="热回收机组供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.heat_recovery, 'heat_recovery')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.heat_recovery, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.hvac_plumbing.air_distribution" :key="'air_distribution'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Air Distribution </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Natural &amp; Mechanical Ventilation"
                  suffix=""
                  hint="自然通风、置换通风、侧送风、顶送风、地板送风等"
                  dense
                  v-model="item.natural_machanical_ventilation"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.air_distribution, 'air_distribution')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.air_distribution, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.hvac_plumbing.water_reuse" :key="'water_reuse'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Water Reuse </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Water Reclaim"
                  suffix=""
                  hint="雨水收集、中水回用、污水净化等"
                  dense
                  v-model="item.water_reclaim"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.hvac_plumbing.water_reuse, 'water_reuse')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.hvac_plumbing.water_reuse, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-subheader>Additional </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="features.hvac_plumbing.additional.supplementary_information"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-6-box</v-icon>
          Home Appliances
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row v-for="(item, index) in features.home_appliances.smart_control" :key="'smart_control'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Smart Control </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Home Automation"
                  suffix=""
                  hint="性能监测、安保防护、控制优化等"
                  dense
                  v-model="item.home_automation"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.smart_control, 'smart_control')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.smart_control, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row v-for="(item, index) in features.home_appliances.thermal_comfort" :key="'thermal_comfort'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Thermal Comfort </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Air Optimizers"
                  suffix=""
                  hint="除湿机、加湿器、空气净化器、新风机、辅助加热设备等"
                  dense
                  v-model="item.air_optimizers"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.thermal_comfort, 'thermal_comfort')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.thermal_comfort, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.lighting" :key="'lighting'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Lighting </v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Natural &amp; Artificial Lighting"
                  suffix=""
                  hint="自然采光、节能灯具等"
                  dense
                  v-model="item.natural_artificial_lighting"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.lighting, 'lighting')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.lighting, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.furniture_living_room" :key="'furniture_living_room'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Furniture (Living Room)</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Sofas, TV, Desks &amp; Chairs, etc."
                  suffix=""
                  hint="沙发、电视、桌椅等"
                  dense
                  v-model="item.sofas_beds_desks_chairs"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.furniture_living_room, 'furniture_living_room')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.furniture_living_room, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.furniture_bed_room" :key="'furniture_bed_room'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Furniture (Bedroom)</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Beds, Closets, Curtain, etc."
                  suffix=""
                  hint="床具、衣橱、窗帘等"
                  dense
                  v-model="item.sofas_beds_desks_chairs"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.furniture_bed_room, 'furniture_bed_room')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.furniture_bed_room, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.furniture_kitchen_room" :key="'furniture_kitchen_room'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Furniture (Kitchen)</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Stove, Refrigerator, Kitchenware, etc."
                  suffix=""
                  hint="炉灶、冰箱、炊具等"
                  dense
                  v-model="item.sofas_beds_desks_chairs"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.furniture_kitchen_room, 'furniture_kitchen_room')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.furniture_kitchen_room, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.furniture_bathroom_room" :key="'furniture_bathroom_room'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Furniture (Bathroom)</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Toilets, Showers, Sinks, etc."
                  suffix=""
                  hint="马桶、淋浴器、洗漱台等"
                  dense
                  v-model="item.sofas_beds_desks_chairs"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.furniture_bathroom_room, 'furniture_bathroom_room')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.furniture_bathroom_room, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
            </v-row>

          <v-row v-for="(item, index) in features.home_appliances.furniture_other_room" :key="'furniture_other_room'+index">
            <v-col cols="2">
              <v-subheader v-if="index===0">Furniture (Others)</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  label="Other furniture"
                  suffix=""
                  hint="其它家具"
                  dense
                  v-model="item.sofas_beds_desks_chairs"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                  label="Manufacturers &amp; Models"
                  suffix=""
                  hint="供应商及产品型号"
                  dense
                  v-model="item.manufacturers_models"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn v-if="index===0" icon small color="success" @click="addRow(features.home_appliances.furniture_other_room, 'furniture_other_room')"><v-icon> mdi-plus-circle </v-icon></v-btn>
              <v-btn v-else icon small color="error" class="ml-1" @click="delRow(features.home_appliances.furniture_other_room, index)"><v-icon> mdi-minus-circle </v-icon></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-subheader>Additional</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="features.home_appliances.additional.supplementary_information"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-7-box</v-icon>
          Media &amp; Communications
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-subheader>WeChat</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Official Account ID"
                  suffix=""
                  hint="微信公众号"
                  dense
                  v-model="
                                    features.media_communications.wechat
                                        .official_account_id
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Weibo</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Official Account ID"
                  suffix=""
                  hint="微博账号"
                  dense
                  v-model="
                                    features.media_communications.weibo
                                        .official_account_id
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Tiktok </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Official Account ID"
                  suffix=""
                  hint="抖音账号"
                  dense
                  v-model="
                                    features.media_communications.tiktok
                                        .official_account_id
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Others</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Social Media &amp; Official Account ID"
                  suffix=""
                  hint="社交平台名称及账号"
                  dense
                  v-model="
                                    features.media_communications.other
                                        .social_media_official_account_id
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Additional</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="
                                    features.media_communications.additional
                                        .supplementary_information
                                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title>
          <v-icon class="mr-3">mdi-numeric-8-box</v-icon>
          Market Potential
        </v-card-title>
        <v-card-subtitle class="font-italic">
          Provide information to all applicable categories. Use
          semicolons (;) to seperate if multiple items applied to one
          input field.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-subheader>Markets</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Target Markets"
                  suffix=""
                  hint="乡村振兴、城市更新、灾后重建、平疫结合等"
                  dense
                  v-model="
                                    features.market_potential.markets
                                        .target_markets
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Climate Regions </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Target Climate Regions"
                  suffix=""
                  hint="严寒地区、寒冷地区、夏热冬冷地区、夏热冬暖地区、温和地区"
                  dense
                  v-model="
                                    features.market_potential.climate_regions
                                        .target_climate_regions
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Clients</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Target Clients"
                  suffix=""
                  hint="使用人群（年龄结构、人口数量等）"
                  dense
                  v-model="
                                    features.market_potential.clients
                                        .target_clients
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Outreach</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Extended Applications"
                  suffix=""
                  hint="装配式、工业化、可变体系、上下叠加应用等"
                  dense
                  v-model="
                                    features.market_potential.outreach
                                        .extended_applications
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Cost</v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Budget for Duplication"
                  prefix="¥"
                  hint="推广再造成本估价（含材料、设备、人工等）"
                  dense
                  v-model="
                                    features.market_potential.cost
                                        .budget_for_duplication
                                "
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-subheader>Additional </v-subheader>
            </v-col>
            <v-col cols="10">
              <v-text-field
                  label="Supplementary Information"
                  suffix=""
                  hint="补充信息"
                  dense
                  v-model="
                                    features.market_potential.additional
                                        .supplementary_information
                                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div v-if="isShow">
        <v-btn
            color="success"
            class="mr-3"
            outlined
            small
            @click="saveData"
            v-if="isdata"
        >
          Save
        </v-btn>
        <v-btn
            color="success"
            class="mr-3"
            outlined
            small
            @click="updateData()"
            v-else
        >
          Update
        </v-btn>
        <v-btn color="warning" outlined small @click="CreateExcel">
          Export
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  addFeature,
  CreateFeatureExcel,
  listFeature,
  updateFeaturePatch,
} from "../../../api/solar/feature";
import { Message } from "element-ui";

export default {
  name: "Features",
  data() {
    return {
      isShow:
          this.$store.getters.groups === 1 ||
          this.$store.getters.groups === 3,
      wordsRules: [(v) => v.length <= 100 || "Max 100 characters"],
      features: {
        summary: {overview_description: "", innovations_highlights: ""},
        design_parameters: {
          general: {height: "", floor_number: "", floor_area: "", window_to_wall_ratio: "", air_change_rate: ""},
          loads: {energy_consumption_density: "", space_heating_load: "", space_cooling_load: ""},
        },
        building_envelope: {
          foundation_structure: {foundation: "", structure: "",},
          exterior_walls: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          interior_walls: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          floors: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          roofs: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          exterior_doors: [
              {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          exterior_windows: [
            {components: "", heat_transfer_coefficient: "", shgc: "", manufacturers_models: ""},
          ],
          new_materials: [
            {description: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        energy_system: {
          photovoltaics: [
              {pv_panels: "", efficiency: "", manufacturers_models: ""}
          ],
          wind: [
            {wind_turbines: "", efficiency: "", manufacturers_models: ""},
          ],
          other_renewable_energy: [
              {description: "", efficiency: "", manufacturers_models: ""},
          ],
          battery: [
              {battery_type: "", capacity: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        hvac_plumbing: {
          space_heating: [
              {heating_source: "", cop: "", manufacturers_models: "",}
          ],
          space_cooling: [
            {cooling_source: "", eer: "", manufacturers_models: ""},
          ],
          domestic_hot_water: [
            {domestic_hot_water_source: "", efficiency: "", manufacturers_models: ""},
          ],
          heat_recovery: [
            {heat_recovery_type: "", efficiency: "", manufacturers_models: ""}
          ],
          air_distribution: [
            {natural_machanical_ventilation: "", manufacturers_models: ""},
          ],
          water_reuse: [
            {water_reclaim: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        home_appliances: {
          smart_control: [
            {home_automation: "", manufacturers_models: ""},
          ],
          thermal_comfort: [
            {air_optimizers: "", manufacturers_models: ""},
          ],
          lighting: [
            {natural_artificial_lighting: "", manufacturers_models: ""},
          ],
          furniture_living_room: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          furniture_bed_room: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          furniture_kitchen_room: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          furniture_bathroom_room: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          furniture_other_room: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        media_communications: {
          wechat: {official_account_id: ""},
          weibo: {official_account_id: ""},
          tiktok: {official_account_id: ""},
          other: {social_media_official_account_id: ""},
          additional: {supplementary_information: ""},
        },
        market_potential: {
          markets: {target_markets: ""},
          climate_regions: {target_climate_regions: ""},
          clients: {target_clients: ""},
          outreach: {extended_applications: ""},
          cost: {budget_for_duplication: ""},
          additional: {supplementary_information: ""},
        },
      },
      old_features: {
        summary: {overview_description: "", innovations_highlights: ""},
        design_parameters: {
          general: {height: "", floor_number: "", floor_area: "", window_to_wall_ratio: "", air_change_rate: ""},
          loads: {energy_consumption_density: "", space_heating_load: "", space_cooling_load: ""},
        },
        building_envelope: {
          foundation_structure: {foundation: "", structure: "",},
          exterior_walls: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          interior_walls: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          floors: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          roofs: [
            {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          exterior_doors: [
              {components: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          exterior_windows: [
            {components: "", heat_transfer_coefficient: "", shgc: "", manufacturers_models: ""},
          ],
          new_materials: [
              {description: "", heat_transfer_coefficient: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        energy_system: {
          photovoltaics: [
              {pv_panels: "", efficiency: "", manufacturers_models: ""}
          ],
          wind: [
            {wind_turbines: "", efficiency: "", manufacturers_models: ""},
          ],
          other_renewable_energy: [
              {description: "", efficiency: "", manufacturers_models: ""},
          ],
          battery: [
              {battery_type: "", capacity: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        hvac_plumbing: {
          space_heating: [
              {heating_source: "", cop: "", manufacturers_models: "",}
          ],
          space_cooling: [
            {cooling_source: "", eer: "", manufacturers_models: ""},
          ],
          domestic_hot_water: [
            {domestic_hot_water_source: "", efficiency: "", manufacturers_models: ""},
          ],
          heat_recovery: [
            {heat_recovery_type: "", efficiency: "", manufacturers_models: ""}
          ],
          air_distribution: [
            {natural_machanical_ventilation: "", manufacturers_models: ""},
          ],
          water_reuse: [
            {water_reclaim: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        home_appliances: {
          smart_control: [
            {home_automation: "", manufacturers_models: ""},
          ],
          thermal_comfort: [
            {air_optimizers: "", manufacturers_models: ""},
          ],
          lighting: [
            {natural_artificial_lighting: "", manufacturers_models: ""},
          ],
          furniture: [
            {sofas_beds_desks_chairs: "", manufacturers_models: ""},
          ],
          additional: {
            supplementary_information: "",
          },
        },
        media_communications: {
          wechat: {official_account_id: ""},
          weibo: {official_account_id: ""},
          tiktok: {official_account_id: ""},
          other: {social_media_official_account_id: ""},
          additional: {supplementary_information: ""},
        },
        market_potential: {
          markets: {target_markets: ""},
          climate_regions: {target_climate_regions: ""},
          clients: {target_clients: ""},
          outreach: {extended_applications: ""},
          cost: {budget_for_duplication: ""},
          additional: {supplementary_information: ""},
        },
      },
      isdata: true,
      featuresId: null,
    };
  },
  watch: {
    "$store.getters.team": {
      handler() {
        this.features = this.old_features;
        listFeature(this.$store.getters.team.id).then((res) => {
          if (res.results.length !== 0) {
            this.features = res.results[0].data;
            this.featuresId = res.results[0].id;
            this.isdata = false;
          }
        });
      },
    },
  },
  created() {
    listFeature(this.$store.getters.team.id).then((res) => {
      if (res.results.length !== 0) {
        this.features = res.results[0].data;
        this.featuresId = res.results[0].id;
        this.isdata = false;
      }
    });
  },
  methods: {
    addRow(arr, str){
      switch (str){
        case "exterior_walls":
          arr.push( {components: "", heat_transfer_coefficient: "", manufacturers_models: ""} )
          break
        case "exterior_windows":
          arr.push( {components: "", heat_transfer_coefficient: "", shgc: "", manufacturers_models: ""} )
          break
        case "new_materials":
          arr.push( {description: "", heat_transfer_coefficient: "", manufacturers_models: ""} )
          break
        case "photovoltaics":
          arr.push( {pv_panels: "", efficiency: "", manufacturers_models: ""} )
          break
        case "wind":
          arr.push( {wind_turbines: "", efficiency: "", manufacturers_models: ""} )
          break
        case "other_renewable_energy":
          arr.push( {description: "", efficiency: "", manufacturers_models: ""} )
          break
        case "battery":
          arr.push( {battery_type: "", capacity: "", manufacturers_models: ""} )
          break
        case "space_heating":
          arr.push( {heating_source: "", cop: "", manufacturers_models: ""} )
          break
        case "space_cooling":
          arr.push( {cooling_source: "", eer: "", manufacturers_models: ""} )
          break
        case "domestic_hot_water":
          arr.push( {domestic_hot_water_source: "", efficiency: "", manufacturers_models: ""} )
          break
        case "heat_recovery":
          arr.push( {heat_recovery_type: "", efficiency: "", manufacturers_models: ""} )
          break
        case "air_distribution":
          arr.push( {natural_machanical_ventilation: "", manufacturers_models: ""} )
          break
        case "water_reuse":
          arr.push( {water_reclaim: "", manufacturers_models: ""} )
          break
        case "smart_control":
          arr.push( {home_automation: "", manufacturers_models: ""} )
          break
        case "thermal_comfort":
          arr.push({air_optimizers: "", manufacturers_models: ""} )
          break
        case "lighting":
          arr.push( {natural_artificial_lighting: "", manufacturers_models: ""} )
          break
        case "furniture_living_room":
          arr.push( {sofas_beds_desks_chairs: "", manufacturers_models: ""} )
          break
        case "furniture_bed_room":
          arr.push( {sofas_beds_desks_chairs: "", manufacturers_models: ""} )
          break
        case "furniture_kitchen_room":
          arr.push( {sofas_beds_desks_chairs: "", manufacturers_models: ""} )
          break
        case "furniture_bathroom_room":
          arr.push( {sofas_beds_desks_chairs: "", manufacturers_models: ""} )
          break
        case "furniture_other_room":
          arr.push( {sofas_beds_desks_chairs: "", manufacturers_models: ""} )
          break
      }
    },
    delRow(arr, index){
      arr.splice(index, 1)
    },
    saveData() {
      const data = {
        team: this.$store.getters.team.id,
        data: this.features,
      };
      addFeature(data)
          .then((res) => {
            this.features = res.data;
            this.featuresId = res.id;
            this.isdata = false;
            Message({
              message: "保存成功",
              type: "success",
            });
          })
          .catch(() => {});
    },
    updateData() {
      const data = {
        data: this.features,
      };
      updateFeaturePatch(this.featuresId, data).then((res) => {
        this.features = res.data;
        this.featuresId = res.id;
        this.isdata = false;
        Message({
          message: "Update Successful",
          type: "success",
        });
      });
    },
    CreateExcel() {
      const data = {
        team: this.$store.getters.team.id,
      };
      CreateFeatureExcel(data).then((res) => {
        const url = process.env.VUE_APP_BACKEND_URL + "/" + res;
        window.open(url);
      });
    },
  },
};
</script>

<style scoped>
</style>