import request from "../../utils/request";

// 查询赛队列表
export function listFeature(team) {
  return request({
    url: '/api/solar/features/',
    method: 'get',
    params: {
      team: team
    }
  })
}


// 查询单个详情
export function getFeature(featureId) {
  return request({
    url: '/api/solar/features/' + featureId + '/',
    method: 'get',
  })
}


// 新增
export function addFeature(data) {
  return request({
    url: '/api/solar/features/',
    method: 'post',
    data: data
  })
}


// 修改(全部修改)
export function updateFeaturePut(featureId, data) {
  return request({
    url: '/api/solar/features/' + featureId + '/',
    method: 'put',
    data: data
  })
}


// 修改(单个字段修改)
export function updateFeaturePatch(featureId, data) {
  return request({
    url: '/api/solar/features/' + featureId + '/',
    method: 'patch',
    data: data
  })
}


// 删除
export function delFeature(featureId) {
  return request({
    url: '/api/solar/features/' + featureId + '/',
    method: 'delete'
  })
}


export function CreateFeatureExcel(data) {
  return request({
    url: '/api/solar/features/create_excel/',
    method: 'post',
    data: data
  })
}