<!--@Time : 2021/11/11 10:17-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-tabs v-model="tabs" centered fixed-tabs icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab v-if="isShow">
						Features
						<v-icon>mdi-star-plus</v-icon>
					</v-tab>

					<v-tab>
						Attachments
						<v-icon>mdi-attachment</v-icon>
					</v-tab>

					<v-tab>
						Personnel
						<v-icon>mdi-account-group</v-icon>
					</v-tab>
				</v-tabs>
			</v-col></v-row
		>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-tabs-items v-model="tabs">
					<v-tab-item v-if="isShow">
						<Features :Tab="tabs"></Features>
					</v-tab-item>
					<v-tab-item>
						<Attachments :Tab="tabs"></Attachments>
					</v-tab-item>
					<v-tab-item>
						<Personnel :Tab="tabs"></Personnel>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Header from "@/components/Header";
import Features from "./Features";
import Attachments from "./Attachments";
import Personnel from "./Personnel";
export default {
	name: "Profile",
	components: { Header, Features, Attachments, Personnel },
	data() {
		return {
			tabs: null,
      isShow: null
		};
	},
  created() {
    this.isShow = this.$store.getters.team !== null
  },
  watch:{
    "$store.getters.team": {
      handler(value){
        this.isShow = value !== null;
      }
    }
  }
};
</script>

<style scoped>
</style>