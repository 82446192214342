<!--@Time : 2021/12/28 10:56-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<v-row class="d-flex justify-end">
      <v-col cols="3" md="3" lg="3">
        <v-select
            style="margin-top: 12px"
            dense
            v-model="chooseCategory"
            :items="chooseCategoryList"
            return-object
            label="Category"
            clearable
            persistent-hint
        >
        </v-select>
      </v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
            v-if="isShow"
            :headers="headers"
            :items="desserts"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalDesserts"
            class="elevation-0"
            disable-sort
        >
					<template v-slot:item.action="{ item }">
						<v-icon
							color="primary"
							small
							class="mr-2"
							@click="editItem(item)"
						>
							mdi-pencil
						</v-icon>
						<v-icon color="error" small @click="deleteItem(item)">
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>
				<v-data-table
					v-else
					:headers="headersShow"
					:items="desserts"
					:loading="loading"
					:options.sync="options"
					:server-items-length="totalDesserts"
					class="elevation-0"
					disable-sort
				>
				</v-data-table>
				
				<div v-if="isShow">
					<v-btn
						color="primary"
						class="mr-3"
						outlined
						small
						@click="addItem"
						>Add a Member</v-btn
					>
					<v-btn color="warning" outlined small @click="exportData"
						>Export</v-btn
					>
				</div>
			</v-col>
		</v-row>
		<!-- add_dialog -->

		<v-dialog v-model="add_dialog" max-width="600">
			<v-card>
				<v-card-title>Add a Member</v-card-title>
				<v-card-text>
					<v-row>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Name (ENG)"
								v-model="userInfo.name_en"
								:rules="rules"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Name (CHN)"
								v-model="userInfo.name_cn"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-select
								v-model="gender"
								:items="genderList"
								item-text="state"
								item-value="abbr"
								label="Gender"
								return-object
								ref="select"
							></v-select>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-autocomplete
								v-model="country"
								:items="countryList"
								item-text="name_en"
								item-value="id"
								label="Nationality"
							></v-autocomplete>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date"
										label="Date of Birth"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="date"
									:active-picker.sync="activePicker"
									:max="
										new Date().toISOString().substr(0, 10)
									"
									min="1950-01-01"
								>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-select
								v-model="category"
								:items="categoryList"
								item-text="state"
								item-value="abbr"
								label="Category"
								return-object
								ref="select"
							>
							</v-select>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-select
								v-model="institute"
								:items="instituteList"
								item-text="name_en"
								item-value="id"
								label="Schools"
							></v-select>
						</v-col>
            <v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Company"
								v-model="company"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-select
								v-model="idType"
								:items="idTypeList"
								item-text="state"
								item-value="abbr"
								label="ID Type"
								return-object
								ref="select"
							></v-select>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="ID Number"
								v-model="userInfo.id_number"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Email"
								v-model="userInfo.email"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Tel"
								v-model="userInfo.telephone"
							>
							</v-text-field>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-select
								v-model="education"
								:items="educationList"
								item-text="state"
								item-value="abbr"
								label="Education"
								return-object
								ref="select"
							></v-select>
						</v-col>
						<v-col :cols="12" md="6" lg="6">
							<v-text-field
								label="Major"
								v-model="userInfo.major"
							>
							</v-text-field>
						</v-col>
						<v-col
							:cols="12"
							md="6"
							lg="6"
							v-if="!team"
						>
							<v-select
								v-model="team"
								:items="teamList"
								item-text="name_en"
								item-value="id"
								label="Team"
								return-object
								ref="select"
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="CloseItem"
						>Close</v-btn
					>
					<v-btn
						v-if="edit"
						color="primary"
						text
						small
						@click="addUser"
						>Save</v-btn
					>
					<v-btn v-else color="primary" text small @click="updateUser"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--delete_dialog-->
		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						small
						@click="dialogDelete = false"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteItemConfirm"
						>Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {
	addPersonnel,
	delPersonnel,
	listPersonnel,
	updatePersonnelPatch,
} from "../../../api/solar/personnel";
import { Message } from "element-ui";
import XLSX from "xlsx";
export default {
	name: "Personnel",
	data() {
		return {
			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 3,
			chooseCategoryList: [
				{ text: "Faculty Advisors", value: 1 },
				{ text: "Student Decathletes", value: 2 },
				{ text: "Sponsors", value: 3 },
				{ text: "Guests", value: 4 },
				{ text: "Organizers", value: 5 },
        { text: "Juries", value: 6 },
			],
			chooseCategory: null,

			headers: [
				{ text: "#", value: "index" },
				{ text: "Name (ENG)", value: "name_en", width: 180 },
				{ text: "Name (CHN)", value: "name_cn", width: 100 },
				{ text: "Gender", value: "gender", width: 80 },
				{ text: "Nationality", value: "nationality", width: 120 },
				{ text: "Date of Birth", value: "date_of_birth", width: 150 },
				{ text: "Category", value: "category", width: 180 },
				{ text: "Institute", value: "institute", width: 400 },
        { text: "Company", value: "company", width: 400 },
				{ text: "ID Type", value: "id_type", width: 100 },
				{ text: "ID Number", value: "id_number", width: 100 },
				{ text: "Email", value: "email", width: 180 },
				{ text: "Tel", value: "telephone", width: 180 },
				{ text: "Education", value: "education", width: 100 },
				{ text: "Major", value: "major", width: 300 },
				{ text: "Action", value: "action", width: 80 },
			],
			headersShow: [
				{ text: "#", value: "index" },
				{ text: "Name (ENG)", value: "name_en", width: 180 },
				{ text: "Name (CHN)", value: "name_cn", width: 100 },
				{ text: "Gender", value: "gender", width: 80 },
				{ text: "Nationality", value: "nationality", width: 120 },
				{ text: "Date of Birth", value: "date_of_birth", width: 150 },
				{ text: "Category", value: "category", width: 180 },
				{ text: "Institute", value: "institute", width: 400 },
        { text: "Company", value: "company", width: 400 },
				{ text: "ID Type", value: "id_type", width: 100 },
				{ text: "ID Number", value: "id_number", width: 100 },
				{ text: "Email", value: "email", width: 180 },
				{ text: "Tel", value: "telephone", width: 180 },
				{ text: "Education", value: "education", width: 100 },
				{ text: "Major", value: "major", width: 300 },
			],
			desserts: [],
			loading: false,
			totalDesserts: 0,
			options: {
				page: 1,
				itemsPerPage: 10,
			},

      rules: [
        value => !!value || 'Required.',
      ],

			// 性别
			gender: null,
			genderList: [
				{ state: "Male", abbr: "2" },
				{ state: "Female", abbr: "1" },
				{ state: "N/A", abbr: "0" },
			],

			// 国家
			country: null,
			countryList: JSON.parse(
				window.sessionStorage.getItem("country_list")
			),

			// 出生日期
			activePicker: null,
			date: null,
			menu: false,

			activePicker1: null,
			date1: null,
			menu1: false,

			// 类别
			category: null,
			categoryList: [
				{ state: "Faculty Advisor", abbr: 1 },
				{ state: "Student Decathlete", abbr: 2 },
				{ state: "Sponsor", abbr: 3 },
				{ state: "Guest", abbr: 4 },
				{ state: "Organizer", abbr: 5 },
        { state: "Jury", abbr: 6 },
			],

			// 证件类型
			idType: null,
			idTypeList: [
				{ state: "ID Card", abbr: 1 },
				{ state: "Passport", abbr: 2 },
			],

			// 所在院校
			institute: null,
			instituteList: JSON.parse(
				window.sessionStorage.getItem("institute_list")
			),
      company: null,

			// 赛队
			team: this.$store.getters.team,
			teamList:
				this.$store.getters.team_list === null
					? []
					: this.$store.getters.team_list,

			// 学历
			education: {},
			educationList: [
				{ state: "Bachelor", abbr: 1 },
				{ state: "Master", abbr: 2 },
				{ state: "Doctor", abbr: 3 },
				{ state: "Others", abbr: 4 },
			],

			// 添加信息
			add_dialog: false,
			dialogDelete: false,

			userInfo: {
				name_cn: null,
				name_en: null,
				date_of_birth: null,
				id_type: null,
				id_number: null,
				email: null,
				telephone: null,
				major: null,
			},
			user: null,
			edit: true,
		};
	},
	async created() {},
	props: ["Tab"],
	watch: {
		"$store.getters.team": {
			handler(val) {
        this.team = val
				this.options.page = 1;
				this.getDataFromApi();
			},
		},
		options: {
			handler() {
				this.getDataFromApi();
			},
			deep: true,
		},
		chooseCategory: {
			handler() {
				this.options.page = 1;
				this.getDataFromApi();
			},
		},
	},
	methods: {
		// 清除添加表单数据
		async CloseItem() {
			this.add_dialog = false;
			this.country = null;
			this.userInfo.name_cn = null;
			this.userInfo.name_en = null;
			this.gender = null;
			this.country = null;
			this.date = null;
			this.category = null;
			this.institute = null;
      this.company = null;
			this.idType = null;
			this.userInfo.id_number = null;
			this.userInfo.email = null;
			this.userInfo.telephone = null;
			this.education = null;
			this.userInfo.major = null;
		},

		async addItem() {
			await this.CloseItem();
			this.add_dialog = true;
			this.edit = true;
		},

		async addUser() {
			const data = {
				name_cn: this.userInfo.name_cn,
				name_en: this.userInfo.name_en,
				gender: this.gender === null ? null: this.gender.abbr,
				nationality: this.country,
				date_of_birth: this.date,
				category: this.category === null ? null: this.category.abbr,
				institute: this.institute,
        company: this.company,
				id_type: this.idType === null ? null: this.idType.abbr,
				id_number: this.userInfo.id_number,
				email: this.userInfo.email,
				telephone: this.userInfo.telephone,
				education: this.education === null ? null: this.education.abbr,
				major: this.userInfo.major,
				team: this.team === null ? null: this.team.id,
			};
			addPersonnel(data).then(() => {
				Message({
					message: "添加成功",
					type: "success",
				});
				this.add_dialog = false;
				this.CloseItem();
        this.getDataFromApi()
			});
		},
		async updateUser() {
			const data = {
        name_cn: this.userInfo.name_cn,
				name_en: this.userInfo.name_en,
				gender: this.gender === null ? null: this.gender.abbr,
				nationality: this.country,
				date_of_birth: this.date,
				category: this.category === null ? null: this.category.abbr,
				institute: this.institute,
        company: this.company,
				id_type: this.idType === null ? null: this.idType.abbr,
				id_number: this.userInfo.id_number,
				email: this.userInfo.email,
				telephone: this.userInfo.telephone,
				education: this.education === null ? null: this.education.abbr,
				major: this.userInfo.major,
				team: this.team === null ? null: this.team.id,
			};
			updatePersonnelPatch(this.personnelId, data).then(() => {
				this.add_dialog = false;
				this.getDataFromApi();
				Message({
					message: "修改成功",
					type: "success",
				});
			});
		},

		async editItem(item) {
			this.add_dialog = true;
			this.edit = false;
			this.personnelId = item.id;
			this.userInfo.name_en = item.name_en;
			this.userInfo.name_cn = item.name_cn;
			this.genderList.forEach((data) => {
				if (data.state === item.gender) {
					this.gender = data;
				}
			});
			this.countryList.forEach((data) => {
				if (data.name_en === item.nationality) {
					this.country = data.id;
				}
			});
			this.date = item.date_of_birth;
			this.categoryList.forEach((data) => {
				if (data.state === item.category) {
					this.category = data;
				}
			});
			this.instituteList.forEach((data) => {
				if (data.name_en === item.institute) {
					this.institute = data.id;
				}
			});
      this.company = item.company;
			this.idTypeList.forEach((data) => {
				if (data.state === item.id_type) {
					this.idType = data;
				}
			});
			this.userInfo.id_number = item.id_number;
			this.userInfo.email = item.email;
			this.userInfo.telephone = item.telephone;
			this.userInfo.major = item.major;
			this.educationList.forEach((data) => {
				if (data.state === item.education) {
					this.education = data;
				}
			});
			this.teamList.forEach((data) => {
				if (data.id === item.team) {
					this.team = data;
				}
			});
		},

		exportData() {
			const ws = XLSX.utils.json_to_sheet(this.desserts);
			ws["!cols"] = [
				{ wch: 5 },
				{ wch: 5 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 7 },
				{ wch: 30 },
				{ wch: 11 },
				{ wch: 20 },
				{ wch: 30 },
        { wch: 30 },
				{ wch: 15 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 10 },
				{ wch: 20 },
				{ wch: 5 },
			];
			const wb = XLSX.utils.book_new();
			// 生成xlsx文件(book,sheet数据,sheet命名)
			XLSX.utils.book_append_sheet(wb, ws, "Personnel");
			// 写文件(book,xlsx文件名称)
			XLSX.writeFile(wb, "Personnel.xlsx");
		},

		deleteItem(item) {
			this.user = item;
			this.dialogDelete = true;
		},

		async deleteItemConfirm() {
			await delPersonnel(this.user.id)
				.then(() => {
					this.dialogDelete = false;
					this.desserts.splice(this.user.index - 1, 1);
					Message({
						message: "Delete Successful",
						type: "success",
					});
					this.user = null;
				})
				.catch(() => {});
		},

		async getDataFromApi() {
			this.loading = true;
			const params = {
				page: this.options.page,
				pagesize: this.pageSize(this.options.itemsPerPage),
			};
			if (this.chooseCategory !== null) {
				params["category"] = this.chooseCategory.value;
			}
			if (this.$store.getters.team !== null) {
				params["team"] = this.$store.getters.team.id;
			}
			listPersonnel(params).then((res) => {
				this.desserts = [];
				this.totalDesserts = res["count"];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						index: index + 1,
						id: item["id"],
						name_cn: item["name_cn"],
						name_en: item["name_en"],
						gender: item["gender"]["text_en"],
						nationality:
							item["nationality"] !== null
								? item["nationality"]["name_en"]
								: null,
						date_of_birth: item["date_of_birth"],
						category: item["category"]["text_en"],
						institute:
							item["institute"] !== null
								? item["institute"]["name_en"]
								: null,
            company: item.company,
						id_type: item["id_type"]["text_en"],
						id_number: item["id_number"],
						email: item["email"],
						telephone: item["telephone"],
						education: item["education"]["text_en"],
						major: item["major"],
						team: item["team"],
					});
				});
				this.loading = false;
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},
	},
};
</script>

<style scoped>
</style>