import request from "../../utils/request";


// 查询列表
export function listAttachment(params) {
  return request({
    url: '/api/solar/attachments/',
    method: 'get',
    params: params
  })
}


// 查询单个详情
export function getAttachment(attachmentId) {
  return request({
    url: `/api/solar/attachments/${attachmentId}/`,
    method: 'get',
  })
}


// 新增
export function addAttachment(data) {
  return request({
    url: '/api/solar/attachments/',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 修改(全部修改)
export function updateAttachmentPut(attachmentId, data) {
  return request({
    url: `/api/solar/attachments/${attachmentId}/`,
    method: 'put',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 修改(单个字段修改)
export function updateAttachmentPatch(attachmentId, data) {
  return request({
    url: `/api/solar/attachments/${attachmentId}/`,
    method: 'patch',
    data: data
  })
}


// 删除
export function delAttachment(attachmentId) {
  return request({
    url: `/api/solar/attachments/${attachmentId}/`,
    method: 'delete'
  })
}
