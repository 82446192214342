import request from "../../utils/request";


// 查询列表
export function listPersonnel(params) {
  return request({
    url: '/api/solar/personnels/',
    method: 'get',
    params: params
  })
}


// 查询单个详情
export function getPersonnel(personnelId) {
  return request({
    url: '/api/solar/personnels/' + personnelId + '/',
    method: 'get',
  })
}


// 新增
export function addPersonnel(data) {
  return request({
    url: '/api/solar/personnels/',
    method: 'post',
    data: data,
  })
}


// 修改(全部修改)
export function updatePersonnelPut(personnelId, data) {
  return request({
    url: '/api/solar/personnels/' + personnelId + '/',
    method: 'put',
    data: data
  })
}


// 修改(单个字段修改)
export function updatePersonnelPatch(personnelId, data) {
  return request({
    url: '/api/solar/personnels/' + personnelId + '/',
    method: 'patch',
    data: data
  })
}


// 删除
export function delPersonnel(personnelId) {
  return request({
    url: '/api/solar/personnels/' + personnelId + '/',
    method: 'delete'
  })
}
